import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, Suspense } from 'react';
import { Spinner } from '../components/spinner/index.js';
/**
 * Wrap a lazy loaded React Component with a spinner
 * Use instead of `<React.Suspense>`
 * @example
 * const MyThing = React.lazy(() => import('./my-thing'));
 * return (<SuspenseLoader><MyThing /></SuspenseLoader>)
 */
export const SuspenseLoader = ({ children, delay = 50, fallback = _jsx(Spinner, {}), forceFallback, }) => {
    const [isSpinnerShown, setSpinnerShown] = useState(false);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setSpinnerShown(true);
        }, delay);
        return () => clearTimeout(timeoutId);
    }, [delay]);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    if (forceFallback)
        return _jsx(_Fragment, { children: fallback });
    return _jsx(Suspense, { fallback: isSpinnerShown && fallback, children: children });
};
