import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
import { Banner } from '../components/banner/index.js';
//
// http://reactjs.org/docs/error-boundaries.html
export class ErrorCatcher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
        };
    }
    componentDidCatch(error, errorInfo) {
        const reloadedKey = 'reloadedFromChunkError';
        const alreadyReloaded = window.sessionStorage.getItem(reloadedKey) === 'yes';
        if ((error.name === 'ChunkLoadError' ||
            error.message.startsWith('Loading CSS chunk')) &&
            !alreadyReloaded) {
            window.sessionStorage.setItem(reloadedKey, 'yes');
            window.location.reload();
            return;
        }
        this.setState({
            error,
        });
        console.error(error);
        console.error(errorInfo.componentStack);
    }
    render() {
        if (this.state.error) {
            return _jsx(Banner, { type: "error", title: this.state.error.message });
        }
        return this.props.children;
    }
}
