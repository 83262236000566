import { KnapsackWordmark } from '@/components/knapsack-logo/knapsack-logo';
import { AccountMenu } from '@/domains/users/components/account-menu';
import { useAppStateMatches } from '@/core/xstate';
import { appHeader } from './app-header.css';

export const AppHeader = () => {
  const isLoggedIn = useAppStateMatches('user.loggedIn');
  return (
    <header className={appHeader} data-testid="app-header">
      <a href="/">
        <KnapsackWordmark color="subtle" />
      </a>
      {isLoggedIn && <AccountMenu />}
    </header>
  );
};
