import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useId } from 'react';
import clsx from 'clsx';
import { FormField } from '../../form-field/index.js';
import { toggle } from './toggle.css.js';
import { useFallbackId } from '../../../hooks.js';
export const InputToggle = forwardRef(({ className, disabled, label, onChange, value = false, id, size = 'medium', readOnly, testId, isValueInherited = false, }, ref) => {
    const canEdit = !disabled && !readOnly;
    const fallbackId = useFallbackId();
    return (_jsx("input", { "aria-label": label, checked: value, className: clsx([className, toggle({ size, isValueInherited })]), "data-testid": testId, disabled: disabled, id: id || fallbackId, onChange: (event) => {
            if (!canEdit)
                return;
            onChange(event.target.checked, event);
        }, ref: ref, type: "checkbox", readOnly: readOnly }));
});
export const FieldToggle = forwardRef((props, ref) => {
    const { label, disabled, error, helperDisplay, helperText, required, labelPosition = 'right', labelWidth, } = props;
    const id = useId();
    return (_jsx(FormField, { disabled: disabled, label: label, error: error, helperDisplay: helperDisplay, helperText: helperText, labelPosition: labelPosition, labelWidth: labelWidth, inputWidth: "auto", required: required, id: id, children: _jsx(InputToggle, { ...props, id: id, ref: ref }) }));
});
