import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { textTitleStyles } from './text-title.css.js';
import { assignThemeVars } from '../text.utils.js';
import { textTheme } from '../text.theme.css.js';
export const TextTitle = ({ align, children, className, display, fontFamily = 'sans', size = 'large', spacing, tag = 'h1', testId, theme, transform, truncate, color, }) => {
    const TextTag = `${tag}`;
    return (_jsx(TextTag, { className: clsx([
            textTitleStyles({
                align,
                color,
                display,
                fontFamily,
                size,
                spacing,
                transform,
                truncate,
            }),
            className,
        ]), "data-testid": testId, style: assignThemeVars(textTheme, theme), children: children }));
};
