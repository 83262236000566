import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { forwardRef, useEffect, useRef, useState, useTransition, } from 'react';
import { collapseWrapper, collapseContent, collapseHeader, collapseHeaderToggle, collapseNestedAction, } from './collapse.css.js';
import { Icon } from '../icon/index.js';
import { TextLabel } from '../text/index.js';
import { IconButtonTertiary, } from '../button/index.js';
import { SuspenseAndErrorWrapper } from '../../utils/suspense-error-wrapper.js';
import { componentSpacing } from '../../css-utils.css.js';
export const Collapse = forwardRef(function CollapseInner({ className, content, depth, details, flush = false, isOpen, nestedAction, onToggle, testId, title, titleIcon, }, ref) {
    const shouldAnimate = useRef(!isOpen);
    const [, startTransition] = useTransition();
    const [collapseState, setCollapseState] = useState(isOpen ? 'opened' : 'closed');
    if (isOpen) {
        if (collapseState === 'closed' || collapseState === 'closing') {
            startTransition(() => {
                setCollapseState('opening');
            });
        }
    }
    else if (collapseState === 'opened' || collapseState === 'opening') {
        startTransition(() => {
            setCollapseState('closing');
        });
    }
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            shouldAnimate.current = true;
        }, 250);
        return () => clearTimeout(timeoutId);
    }, []);
    useEffect(() => {
        if (collapseState === 'closing') {
            const closeTimeout = setTimeout(() => {
                startTransition(() => {
                    setCollapseState('closed');
                });
            }, 50);
            return () => clearTimeout(closeTimeout);
        }
        if (collapseState === 'opening') {
            const openTimeout = setTimeout(() => {
                startTransition(() => {
                    setCollapseState('opened');
                });
            }, 500);
            return () => clearTimeout(openTimeout);
        }
    }, [collapseState, setCollapseState]);
    // Handle Content Height
    const contentRef = useRef(null);
    const contentHeight = collapseState === 'closed' ? 0 : contentRef.current?.scrollHeight;
    // Handle User Interaction
    function handleToggle() {
        startTransition(() => {
            onToggle?.();
            setCollapseState((s) => (s === 'opened' ? 'closing' : 'opening'));
        });
    }
    return (_jsxs("div", { className: clsx([className, collapseWrapper({ flush })]), "data-testid": testId, ref: ref, children: [_jsxs("div", { className: collapseHeader({ opened: isOpen, depth }), children: [_jsxs("button", { className: collapseHeaderToggle, onClick: () => handleToggle(), onKeyDown: (e) => {
                            if (e.key === 'Enter') {
                                handleToggle();
                            }
                        }, type: "button", tabIndex: 0, children: [_jsx(Icon, { color: "inherit", size: "xsmall", symbol: isOpen ? 'caret-down' : 'caret-right' }), titleIcon && _jsx(Icon, { ...titleIcon }), typeof title === 'string' ? (_jsx(TextLabel, { weight: "medium", size: "medium", truncate: true, children: title })) : (title), details && (_jsx(TextLabel, { className: componentSpacing({
                                    marginLeft: 'auto',
                                    marginRight: !nestedAction ? 'medium' : undefined,
                                }), color: "subtle", size: "medium", truncate: true, weight: "regular", children: details }))] }), nestedAction && (_jsx("span", { className: collapseNestedAction, children: _jsx(IconButtonTertiary, { ...nestedAction, color: nestedAction.color || 'subtle', size: nestedAction.size || 'small' }) }))] }), _jsx("div", { className: collapseContent({ animate: shouldAnimate.current }), ref: contentRef, style: { height: isOpen ? 'auto' : contentHeight }, children: _jsx(SuspenseAndErrorWrapper, { children: collapseState === 'closed' ? null : content }) })] }));
});
