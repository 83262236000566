import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { flexStyles } from './flex.css.js';
export const Flex = ({ children, className, align = 'center', direction = 'row', gap, justify, testId, noWrap = false, grow, shrink, }) => {
    return (_jsx("div", { className: clsx(className, flexStyles({
            align,
            direction,
            gap,
            justify,
            noWrap,
        })), "data-testid": testId, style: {
            flexGrow: grow,
            flexShrink: shrink,
        }, children: children }));
};
