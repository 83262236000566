import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { textLabelStyles } from './text-label.css.js';
import { assignThemeVars } from '../text.utils.js';
import { textTheme } from '../text.theme.css.js';
/**
 * @deprecated Use `TextBody` instead
 * @todo https://linear.app/knapsack/issue/KSP-5231/replace-all-instances-of-textlabel-with-textbody
 */
export const TextLabel = ({ align, children, className, color, display, fontFamily = 'sans', size = 'medium', spacing, tag = 'span', testId, transform, truncate, weight = 'regular', theme, }) => {
    const TextTag = `${tag}`;
    return (_jsx(TextTag, { className: clsx([
            textLabelStyles({
                align,
                color,
                display,
                fontFamily,
                size,
                spacing,
                transform,
                truncate,
                weight,
            }),
            className,
        ]), "data-testid": testId, style: assignThemeVars(textTheme, theme), children: children }));
};
