import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import { assertUniqueIdInArray } from '@knapsack/utils';
import { useShallowCompareEffect } from '@knapsack/hooks';
import { Collapse } from '../collapse/index.js';
import { accordion, accordionItem } from './accordion.css.js';
import { unstyledList } from '../../css-utils.css.js';
export const Accordion = ({ allowMultiple, items: accordionItems, initiallyOpenIds = [], unstyled = false, }) => {
    assertUniqueIdInArray({ items: accordionItems, key: 'id' });
    if (!initiallyOpenIds.every((openId) => {
        return accordionItems.some((x) => x.id === openId);
    })) {
        throw new Error(`Accordion 'initiallyOpenIds' prop must be a subset of the accordion items ids (${accordionItems
            .map(({ id }) => id)
            .join(', ')}) but provided: ${initiallyOpenIds.join(', ')}`);
    }
    const [openIds, setOpenIds] = useState(initiallyOpenIds);
    const itemIds = accordionItems.map((x) => x.id);
    const itemIdsRef = useRef(itemIds);
    const handleToggle = useCallback((id) => {
        setOpenIds((prev) => {
            if (allowMultiple) {
                if (prev.includes(id)) {
                    return prev.filter((x) => x !== id);
                }
                return [...prev, id];
            }
            if (prev.includes(id)) {
                return [];
            }
            return [id];
        });
    }, [allowMultiple]);
    useShallowCompareEffect(() => {
        // This opens the last item if it was added to the accordion after the initial render
        if (itemIds.length === itemIdsRef.current.length + 1) {
            const lastId = itemIds.at(-1);
            if (!openIds.includes(lastId)) {
                handleToggle(lastId);
            }
        }
        itemIdsRef.current = itemIds;
    }, [itemIds, handleToggle]);
    return (_jsx(React.StrictMode, { children: _jsx("ul", { className: clsx(unstyledList, accordion({ unstyled })), children: accordionItems.map((item) => (_jsx("li", { className: accordionItem, children: _jsx(Collapse, { content: item.content, depth: item.depth, details: item.details, flush: true, isOpen: openIds.includes(item.id), nestedAction: item.nestedAction, onToggle: () => handleToggle(item.id), testId: item.testId, title: item.title, titleIcon: item.titleIcon }) }, item.id))) }) }));
};
