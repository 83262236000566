import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { withFormField } from '../../form-field/index.js';
import { inputContent, inputWrapper, } from '../../../shared-styles/inputs.css.js';
import { inputText } from '../text-input/text-input.css.js';
import { Icon } from '../../icon/index.js';
import { Badge } from '../../badge/index.js';
import { TextLabel } from '../../text/index.js';
import { NestedAction } from '../nested-action/index.js';
export const InputNumber = forwardRef(({ onChange, value, size = 'medium', autoComplete = true, step, id, name, label, maxLength, minLength, onBlur, onFocus, onKeyDown, onKeyUp, required, testId, disabled, readOnly, placeholder, icon, nestedAction, suffix, prefix, seamless = false, beforeInput, minValue, maxValue, }, ref) => {
    const hasBeforeInput = !!beforeInput || !!icon || !!prefix;
    const hasAfterInput = !!suffix || !!nestedAction;
    return (_jsxs("div", { className: inputWrapper({ disabled, readOnly, seamless }), children: [hasBeforeInput && (_jsxs("span", { className: inputContent({ placement: 'before' }), children: [beforeInput, icon && (_jsx(Icon, { color: disabled ? 'disabled' : 'subtle', size: "small", symbol: icon, spinning: icon === 'loading' })), prefix && (_jsx(TextLabel, { color: "subtle", size: "medium", children: prefix }))] })), _jsx("input", { id: id, name: name, disabled: disabled, readOnly: readOnly, placeholder: placeholder, onBlur: onBlur, onFocus: onFocus, onKeyDown: onKeyDown, onKeyUp: onKeyUp, required: required, onChange: (event) => {
                    onChange(Number(event.target.value), event);
                }, type: "number", value: value, className: inputText({ hasIcon: !!icon, hasPrefix: !!prefix, size }), ref: ref, step: step || 'any', autoComplete: autoComplete ? 'on' : 'off', "aria-required": required ? 'true' : 'false', "data-testid": testId, "aria-label": label, maxLength: maxLength, minLength: minLength, min: minValue, max: maxValue }), hasAfterInput && (_jsxs("span", { className: inputContent({ placement: 'after' }), children: [suffix && _jsx(Badge, { label: suffix, size: "small", weight: "light" }), nestedAction && !disabled && _jsx(NestedAction, { ...nestedAction })] }))] }));
});
export const FieldNumberInput = withFormField(InputNumber);
