import { FloatingAction } from '@knapsack/toby';
import { useKsLocation } from '@/utils/ks-location.hooks';

const SupportChatTrigger = () => {
  const { type: locationType } = useKsLocation();
  return (
    <FloatingAction
      icon="chat"
      label="Contact Knapsack"
      onTrigger={async () => {
        const { showKnapsackFeedbackModal } = await import(
          '@/core/modals/knapsack-support-modal'
        );
        showKnapsackFeedbackModal({ locationType });
      }}
      placement="bottom-right"
      tooltipPlacement="left"
      testId="supportChatTrigger"
      zIndex={100}
    />
  );
};

export default SupportChatTrigger;
