import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { ButtonBase, IconButtonBase, } from '../button-base/index.js';
import { buttonSecondaryStyles, } from './button-secondary.css.js';
export const ButtonSecondary = forwardRef(({ color = 'default', ...butttonBaseProps }, ref) => {
    return (_jsx(ButtonBase, { ref: ref, theme: buttonSecondaryStyles({ color }), ...butttonBaseProps }));
});
export const IconButtonSecondary = forwardRef(({ color = 'default', ...butttonBaseProps }, ref) => {
    return (_jsx(IconButtonBase, { ref: ref, theme: buttonSecondaryStyles({ color }), ...butttonBaseProps }));
});
